import cx from 'classnames';
import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import CatList from '../../components/CategoryList/CatList';
import MdProjectList from '../../components/ProjectsList/MdProjectsList';
import SEO from '../../components/seo';
import { usePageContext } from '../../contexts/pageContext';
import { useMdData } from '../../static-queries/useBlogData';
import styles from '../blog/blog.module.scss';
import projectsStyles from './projects.module.scss';

type IProjectsProps = {};

const Projects: React.FC<IProjectsProps> = ({}) => {
  const data = useMdData();
  const projects = data.projects.edges;
  const {setIsSideBarShown, isSideBarShown} = usePageContext();
  const [headerOpacity, setHeaderOpacity] = useState(1);

  const handleScroll = () => {
    const distanceFromTop = window.scrollY;
    const newOpacity = Math.max(1 - distanceFromTop / 60, 0);
    setHeaderOpacity(newOpacity);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderSymbol = (type: string) => {
    return type === 'blog'
      ? '☱'
      : type === 'project'
      ? '☆'
      : type === 'graphic'
      ? '⛋'
      : '·';
  };

  return (
    <React.Fragment>
      <SEO title={'Projects | Gordo Labs'} />
      <div className={projectsStyles.background}></div>
      <article
        className={cx(
          'w-full px-6 pb-6 min-h-screen flex items-center flex-col',
          projectsStyles.mainContainer
        )}>
        <div
          className={cx('hidden md:block w-full', !isSideBarShown && 'pl-8')}>
          <CatList />
        </div>
        <section
          className={cx(
            styles.introSection,
            'flex items-center justify-center flex-col relative text-center overflow-hidden md:py-10 py-8 w-full mb-1'
          )}>
          <h2
            className="font-gordo text-xl md:text-3xl tracking-wider rounded-2xl px-4 py-2  items-center flex gap-3 md:mt-0 mt-20"
            style={{opacity: headerOpacity}}>
            <span>☆</span>
          </h2>

          {/*          <div className="font-charisma ml-4 font-light mt-6 text-gray-400
          text-sm text-center" dangerouslySetInnerHTML={{__html:projectsMetaData.introSection.description}}/>*/}
        </section>

        <section className={projectsStyles.base}>
          <MdProjectList list={projects} />
        </section>
      </article>
    </React.Fragment>
  );
};

Projects.defaultProps = {};

export default Projects;
